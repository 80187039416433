import { Directive } from '@angular/core';

@Directive({
  selector: '[appFiledrag]'
})
export class FiledragDirective {

  constructor() { }

}
